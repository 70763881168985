import React from 'react'
import { graphql } from 'gatsby'

import Masonry from 'react-masonry-component'
import { Fade, Layout } from '../components'

const masonryOptions = {
  transitionDuration: 0,
}
const imagesLoadedOptions = { background: '.preview__cover' }

class Print extends React.Component {
  title = 'Print'

  renderSpan(key, node) {
    if (node[key]) {
      return (
        <span>
          {node[key]}
          <br />
        </span>
      )
    } else {
      return ''
    }
  }

  render() {
    return (
      <Layout page={this}>
        <div className="print wrapper">
          <Masonry
            className={'print__grid'}
            options={masonryOptions}
            disableImagesLoaded={false}
            updateOnEachImageLoad={false}
            imagesLoadedOptions={imagesLoadedOptions}
          >
            {this.props.data.datoCmsPrintpage.item.map(node => (
              <div key={node.id} className="w-full md:w-1/2 lg:w-1/3">
                <div
                  className="print__item"
                  style={{
                    paddingTop: `${(node.picture.height / node.picture.width) *
                      100}%`,
                  }}
                >
                  <Fade
                    to={`/print/${node.slideshow.slug}/`}
                    className="print__preview refresh"
                  >
                    <div className="preview w-full h-full">
                      <div className="preview__image">
                        <img
                          src={`${node.picture.url}?auto=format&fit=min&w=800`}
                          alt=""
                        />
                      </div>
                      <div className="preview__title preview__title--overlay">
                        <div className="preview__on-hover">
                          <h2 className="preview__on-hover-title font-head text-24 lg:text-28 uppercase tracking-wide leading-tight">
                            {node.title}
                          </h2>
                          <span className="preview__on-hover font-main text-12 lg:text-14 uppercase leading-tight">
                            {this.renderSpan('subtitle', node)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Fade>
                </div>
              </div>
            ))}
          </Masonry>
        </div>
      </Layout>
    )
  }
}

export default Print

export const query = graphql`
  query printQuery {
    datoCmsPrintpage {
      item {
        id
        title
        subtitle
        picture {
          url
        }
        slideshow {
          title
          subtitle
          slug
          slides {
            picture {
              url
              width
              height
            }
          }
        }
      }
    }
  }
`
